module.exports = [{
      plugin: require('/home/circleci/app/node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/home/circleci/app/src/layouts/index.js"},
    },{
      plugin: require('/home/circleci/app/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/circleci/app/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/circleci/app/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-151411510-1"},
    },{
      plugin: require('/home/circleci/app/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
